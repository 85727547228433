import React, { Component } from 'react';

import BaseEntities from 'components/BaseEntities';
import Table from 'components/Entities/Table';
import TypicalProblemList from 'components/Entities/TypicalProblemList';
import PollList from 'components/Entities/PollList';
import TypicalEstablishmentList from 'components/Entities/TypicalEstablishmentList';
import TypicalInitiativeList from 'components/Entities/TypicalInitiativeList';
import BudgetInitiativeProjectList from 'components/Entities/BudgetInitiativeProjectList';
import CompetitionInitiativeProjectList from 'components/Entities/CompetitionInitiativeProjectList';
import CompetitionInitiativeList from 'components/Entities/CompetitionInitiativeList';
import PersonTile from 'components/Entities/PersonTile';
import ParticularProblemTile from 'components/Entities/ParticularProblemTile';
import ParticularProblemList from 'components/Entities/ParticularProblemList';
import ParticularProblemYMap from 'components/Entities/ParticularProblemYMap';
import ParticularProblemYHeatMap from 'components/Entities/ParticularProblemYHeatMap';
import ParticularEstablishmentTile from 'components/Entities/ParticularEstablishmentTile';
import ParticularEstablishmentList from 'components/Entities/ParticularEstablishmentList';
import ParticularInitiativeTile from 'components/Entities/ParticularInitiativeTile';
import ParticularInitiativeList from 'components/Entities/ParticularInitiativeList';
import PublicationTile from 'components/PublicationTile';
import PublicationList from 'components/PublicationList';
import PublicationYMap from 'components/PublicationYMap';
import YHeatMap from 'components/Entities/YHeatMap';
import ResponsiblePersonList from 'components/Entities/ResponsiblePersonList';
import DiscussionInitiativeList from "./Entities/DiscussionInitiativeList";


export default class Entities extends Component {

    static getTemplates() {
        return Object.assign(BaseEntities.getTemplates(), {
            "typical_problem_list": TypicalProblemList,
            "typical_establishment_list": TypicalEstablishmentList,
            "typical_initiative_list": TypicalInitiativeList,
            "budget_initiative_project_list": BudgetInitiativeProjectList,
            "competition_initiative_project_list": CompetitionInitiativeProjectList,
            "competition_initiative_list": CompetitionInitiativeList,
            "discussion_initiative_list": DiscussionInitiativeList,
            "poll_list": PollList,
            "person_tile": PersonTile,
            "publication_tile": PublicationTile,
            "publication_list": PublicationList,
            "publication_ymap": PublicationYMap,
            "table": Table,
            "particular_problem_tile": ParticularProblemTile,
            "particular_problem_list": ParticularProblemList,
            "particular_problem_ymap": ParticularProblemYMap,
            "particular_problem_yheatmap": ParticularProblemYHeatMap,
            "particular_establishment_tile": ParticularEstablishmentTile,
            "particular_establishment_list": ParticularEstablishmentList,
            "particular_initiative_tile": ParticularInitiativeTile,
            "particular_initiative_list": ParticularInitiativeList,
            "yheatmap": YHeatMap,
            "responsible_persons_list": ResponsiblePersonList,
        });
    }

    render() {
        return <BaseEntities {...this.props} getTemplates={Entities.getTemplates}/>;
    }
}

